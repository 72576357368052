import * as React from "react"

import { dimensions, styled } from "src/styles/theme"

const StyledHeaderSection = styled.header`
  display: "flex";
  width: 100%;
  padding: 0;
  padding-top: 5vh;
  width: ${dimensions.mobileWidth};
  align-items: "center";
  justify-content: "center";
`

const HeaderSection: React.FC = ({ children }) => {
  if (children) {
    return <StyledHeaderSection>{children}</StyledHeaderSection>
  } else {
    return null
  }
}

export default HeaderSection
