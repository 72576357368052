import * as React from "react"
import { useState } from "react"

import { breakpoints, styled } from "src/styles/theme"

const StyledIngredientList = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  border-right: 1px solid ${(props) => props.theme.canvasLine};
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    align-items: center;
    justify-content: center;
    border-right: none;
  }
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: ${breakpoints.sm}) {
    align-items: center;
    justify-content: center;
  }
`

const StyledTextLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

const SmallUnderline = styled.small`
  text-decoration: underline;
`

const IngredientList: React.FC<{ data: GatsbyTypes.RecipeTemplateQuery }> = ({
  data
}) => {
  const [cookingFor, setCookingFor] = useState(2)
  const ingredientSections = data?.allMarkdownRemark?.nodes[0]?.frontmatter?.ingredientSections?.map(
    (section, sectionIndex) => (
      <StyledSection key={sectionIndex}>
        <h6>{section?.title}</h6>
        {section?.items?.map((item, itemIndex) => (
          <StyledTextLine key={itemIndex}>
            <small>{item?.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
            <SmallUnderline>
              {(item?.quantity && cookingFor * item.quantity) || ""} {item?.unit}
            </SmallUnderline>
          </StyledTextLine>
        ))}
      </StyledSection>
    )
  )
  return (
    <StyledIngredientList>
      <h3>Ingredients</h3>
      <StyledTextLine>
        <small>Cooking for:&nbsp;&nbsp;&nbsp;</small>
        <SmallUnderline>{cookingFor}</SmallUnderline>
      </StyledTextLine>
      {ingredientSections}
    </StyledIngredientList>
  )
}

export default IngredientList
