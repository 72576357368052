import * as React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import moment from "moment"
import { styled, breakpoints } from "src/styles/theme"

const StyledArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 5vh 0;
`

const StyledHeading = styled.h3`
  padding: 0.25rem 0;
  margin: 0;
`

const StyledSubheading = styled.p`
  padding: 0.25rem 0;
  margin: 0;
  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
  }
`

const ImagePlaceholder = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  flex: 0 0 auto;
  background-color: ${(props) => props.theme.paleInk};
`

const StyledImage = styled(Image)`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  flex: 0 0 auto;
`

const StyledTitleContainer = styled.div`
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
`

const MetaContainer = styled.div`
  height: 2rem;
`

const Meta = styled.small`
  color: ${(props) => props.theme.ink};
  padding-right: 0.75rem;
  line-height: 2rem;
  opacity: 0.6;
`

interface ArticleSnapshotProps {
  slug?: string
  fluidThumbnail: GatsbyTypes.Maybe<
    Pick<
      GatsbyTypes.ImageSharpFluid,
      "aspectRatio" | "sizes" | "base64" | "src" | "srcSet"
    >
  >
  title?: string
  subtitle?: string
  date?: string
  timeToRead?: number
  category?: string
}

const ArticleSnapshot: React.FC<ArticleSnapshotProps> = ({
  slug,
  fluidThumbnail,
  title,
  subtitle,
  date,
  timeToRead,
  category
}) => {
  if (!title || !subtitle || !slug) {
    return null
  }

  let thumbnailImage = <ImagePlaceholder />
  if (fluidThumbnail) {
    thumbnailImage = <StyledImage fluid={fluidThumbnail} />
  }
  return (
    <Link to={`/${slug}`}>
      <StyledArticleContainer>
        {thumbnailImage}
        <StyledTitleContainer>
          <MetaContainer>
            <Meta>{`🗓️ ${moment(date).format("MMMM Do YYYY")}`}</Meta>
            <Meta>·</Meta>
            <Meta>{`⏳ ${timeToRead} min`}</Meta>
          </MetaContainer>
          <StyledHeading>{title}</StyledHeading>
          <StyledSubheading>{subtitle}</StyledSubheading>
        </StyledTitleContainer>
      </StyledArticleContainer>
    </Link>
  )
}

export default ArticleSnapshot
